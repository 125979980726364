import styles from "./bannerError.module.css";
import Icon from "@/(svgIcons)/bannerErrorIcon.svg";
import RedCloseIcon from "@/(svgIcons)/redClose.svg";

const BannerError = ({ closeId, title = "", message = "" }) => {
  return (
    <div className={styles.bannerError}>
      <div style={{ margin: "4px 0px" }}>
        <Icon style={{ display: "flex" }} />
      </div>

      <div
        className={styles.bannerTextContainer}
        style={!message ? { justifyContent: "center" } : {}}
      >
        <div className={styles.bannerTitle}>{capitalizeFirstLetter(title)}</div>

        {message && (
          <div className={styles.bannerText}>
            {capitalizeFirstLetter(message)}
          </div>
        )}
      </div>

      <div className={styles.closeButtonContainer} id={closeId}>
        <RedCloseIcon />
      </div>
    </div>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default BannerError;
