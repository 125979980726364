"use client";

import { createContext, useCallback, useContext, useRef, useState } from "react";
import Modal from "../Modal/Modal";

const ConfirmDialog = createContext();

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();

  const confirm = useCallback(
    (title) => {
      return new Promise((resolve) => {
        setState({ title, isOpen: true });

        fn.current = (choice) => {
          resolve(choice);
          setState({ isOpen: false });
        };
      });
    },
    [setState]
  );

  const setOpen = (value) => {
    fn.current(false);
  };

  const styles = {
    container: { display: "flex", flexDirection: "column", gap: "8px", padding: "15px 20px" },
    buttonsContainer: { marginTop: "10px", gap: "8px" },
    button: { padding: "10px 17px" },
  };

  const confirmJsx = (
    <div style={styles.container}>
      <h4>{state?.title}</h4>

      <div className="flex-align-center" style={styles.buttonsContainer}>
        <button onClick={() => fn.current(true)} style={styles.button}>
          Yes
        </button>
        <button className="secondary" onClick={() => fn.current(false)} style={styles.button}>
          No
        </button>
      </div>
    </div>
  );

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Modal
        isOpen={state?.isOpen}
        setIsOpen={setOpen}
        closeOnOutsideClick={false}
        removeCloseButton={true}
      >
        {confirmJsx}
      </Modal>
    </ConfirmDialog.Provider>
  );
}

export function useConfirm() {
  return useContext(ConfirmDialog);
}
