import { API_URL } from "@/app/config";
import showError from "@/components/BannerError/showError";
import { deleteCookie } from "cookies-next";

export class ReqError {
  constructor({
    response,
    data = null,
    title = "",
    customMessage = "",
    rerouteToLogin = true,
    showToastError = true,
  }) {
    this.message = "";
    if (customMessage) this.message = customMessage;
    else if (data) {
      let validationErrors =
        data?.validation_errors?.length > 0
          ? data?.validation_errors.map(
              (validationErr) =>
                `${validationErr?.attribute} ${validationErr?.message}`
            )
          : "";
      if (validationErrors?.length > 0)
        validationErrors = validationErrors.join(", ");

      this.message = validationErrors ? validationErrors : data?.message;
      if (!this.message) this.message = data?.error;
    }

    if (showToastError) showError(title, this.message);

    if (response?.status === 401) logout(rerouteToLogin);
  }
}

function logout(rerouteToLogin) {
  deleteCookie("auth");

  fetch(`${API_URL}/log_out`, { method: "DELETE" });

  rerouteToLogin ? window.location.replace("/login") : window.location.reload();
}
