"use client";

import { useEffect, useRef } from "react";
import styles from "./Modal.module.css";
import BigNoOutlineClose from "@/(svgIcons)/bigNoOutlineClose.svg";

const Modal = ({
  isOpen,
  setIsOpen,
  closeOnOutsideClick = true,
  removeCloseButton = false,

  children,
}) => {
  const modalRef = useRef(null);
  const modalContainerRef = useRef(null);
  const closeButtonRef = useRef(null);

  // Closes the modal when clicking outside of it
  useEffect(() => {
    if (closeOnOutsideClick) {
      const handleClickOutside = (event) => {
        if (
          (modalRef.current &&
            !modalRef.current.contains(event.target) &&
            modalContainerRef.current &&
            modalContainerRef.current.contains(event.target)) ||
          (closeButtonRef.current && closeButtonRef.current.contains(event.target))
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, []);

  return (
    <div
      className={isOpen ? styles.modalContainer : styles.modalContainerClosed}
      ref={modalContainerRef}
    >
      <div className={styles.modal} ref={modalRef}>
        {!removeCloseButton && (
          <div ref={closeButtonRef} onClick={() => setIsOpen(false)}>
            <BigNoOutlineClose className={styles.modalCloseIcon} />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default Modal;
